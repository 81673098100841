<template>
  <v-container class="px-10">
    <v-row justify="center">
      <v-col md="12">
        <v-btn color="success" class="my-9 text-capitalize" @click="() => {
      readMode = false; item = null; display = true; this.setPayment(null) }"
        >{{ $t('hr.payment.add') }}
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col class="mt-8">
        <h3 class="mb-2">{{ $router.currentRoute.name }}</h3>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          class="mb-3"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table :dark="$store.state.isDarkMode"
          :search="search"
          :headers="headers"
          id="table"
          :items="data"
          :loading="$store.state.payments.loading"
          style="border: 1px solid #999"
          :options.sync="$store.state.payments.options"
          :server-items-length="$store.state.payments.serverTotal"
          disable-sort
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-center">
              <v-icon
                color="red"
                style="cursor: pointer"
                class="px-2"
                @click="handleDelete(item)"
              >mdi-delete
              </v-icon>
              <v-icon
                color="blue"
                class="mx-2"
                style="cursor: pointer"
                @click="handleDisplay(item)"
              >mdi-eye
              </v-icon>
            </div>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <PaymentModal v-model="display" :item="item" :read-mode="readMode"></PaymentModal>
  </v-container>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import PaymentModal from "@/views/dashboard/modules/HR/payments/PaymentModal.vue";

export default {
  components: {PaymentModal},
  data() {
    return {
      search: '',
      readMode: false,
      display: false,
      item: null,
    };
  },
  mounted() {
    this.fetch()
  },
  watch: {
    "$store.state.payments.options": function () {
      this.fetch()
    },
  },
  computed: {
    ...mapGetters({
      data: 'payments/data',
      headers: 'payments/headers'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'payments/fetch',
      delete: 'payments/delete',
      setPayment: 'payments/setPayment'
    }),
    handleDisplay(item) {
      this.display = true
      this.item = item
      this.setPayment(item)
      this.readMode = true
    },
    async handleDelete(item) {
      await this.delete(item.id)
    },
    handleEdit(item) {
      this.item = item
      this.display = true
    },
  },
};
</script>
<style scoped>
#table >>> th, #table >>> td {
  text-align: center !important;
}
</style>
