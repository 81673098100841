<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="98%" :value="value" @input="$emit('input', $event)" >
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation
    >
      <v-card class="pt-8">
        <v-card-text style="height: 80vh">
          <VRow>
            <v-col md="3">
              <v-text-field :disabled="readMode" dense :rules="requiredRule()" :label="$t('hr.time.to-date')" type="date" outlined
                            v-model="form.generated_to">
              </v-text-field>
            </v-col>
            <v-col md="3">
              <v-autocomplete :disabled="readMode" dense :items="employees" item-text="name" item-value="id"
                              @change="form.contract = null,form.shift = null" :label="$t('hr.time.emp-name')" outlined
                              v-model="form.employee">
              </v-autocomplete>
            </v-col>
            <v-col md="3">
              <v-autocomplete :disabled="readMode" dense :items="contracts" item-text="name" item-value="id"
                              @change="form.employee = null,form.shift = null" :label="$t('hr.calculation.contract')"
                              outlined v-model="form.contract">
              </v-autocomplete>
            </v-col>
            <v-col md="3">
              <v-autocomplete :disabled="readMode" dense :items="shifts" item-text="name" item-value="id"
                              @change="form.contract = null,form.employee = null" :label="$t('hr.calculation.shift')"
                              outlined v-model="form.shift">
              </v-autocomplete>
            </v-col>
          </VRow>
          <v-row>
            <v-col md="8">
              <v-text-field :disabled="readMode"  dense :label="$t('hr.vacation.note')" outlined v-model="form.note">
              </v-text-field>
            </v-col>
            <v-col md="4">
              <v-btn  v-if="!readMode" color="blue" style="width: 100%; color: white" @click="submit" type="button">{{
                  $t('hr.payment.grab')
                }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-8"></v-divider>
          <v-form ref="form2" @submit.prevent="submit" lazy-validation>
            <v-row v-if="isThereCalculation && (!readMode)" >
              <v-col sm="3">
                <v-autocomplete dense @update:search-input="handleSearch($event)" outlined :items="cashs"
                                v-model="paymentSubmit.cash_account_id" item-text="name"
                                :label="$t('hr.loan.cash')"
                                item-value="id" :rules="requiredRule()"></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-text-field dense outlined disabled v-model="summary"
                              :label="$t('hr.payment.summary')"></v-text-field>
              </v-col>
              <v-col sm="2">
                <v-btn width="100%" @click="submitPayments" :disabled="!isThereCalculation" class="mx-1"
                       style="color: white" color="blue">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
              <v-col sm="2">
                <v-btn width="100%" :disabled="!isThereCalculation" outlined class="mx-1" 
                       @click="$emit('input', false)">
                  {{ $t('cancel') }}
                </v-btn>
              </v-col>
              <v-col sm="2" hidden="hidden">
                <v-btn width="100%" @click="exportPayment" :disabled="!isThereCalculation" outlined class="mx-1">{{
                    $t('hr.time.export')
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                class="mb-3"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-data-table :dark="$store.state.isDarkMode"
              :headers="headers"
              id="table"
              :search="search"
              class="flex-grow-1"
              :items="data"
              :loading="$store.state.calculations.loading"
              style="border: 1px solid #999"
              hide-default-footer
            >
              <template v-slot:item.amount_payed="{ item }">
                <v-text-field value="0" v-model="item.salary" dense type="number" class="mt-5" outline
                              :rules="requiredRule"></v-text-field>
              </template>
            </v-data-table>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import {requiredRule} from "@/helpers/validation-rules";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PaymentModal',
  data() {
    return {
      search: '',
      valid: false,
      form: {
        generated_to: '',
        employee: null,
        contract: null,
        shift: null,
        note: '',
      },
      paymentSubmit: {
        cash_account_id: '',
        
      }
    }
  },
  methods: {
    ...mapActions({
      fetchEmployees: 'employees/fetchEmployees',
      fetchContracts: 'employees/fetchContracts',
      fetchShifts: 'employees/fetchShifts',
      restorePayment: 'payments/restorePayment',
      fetchCashs: 'employees/fetchCashs',
      submitPaymentsAction: 'payments/submitPaymentsAction',
    }),
    async handleSearch(search) {
      if (search) {
        await this.fetchCashs(search)
      }
    },
    requiredRule() {
      return requiredRule
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        this.restorePayment(this.form)
      }
    },
    async submitPayments() {
      const valid = await this.$refs.form2.validate() && await this.$refs.form.validate();
      if (valid) {
        this.submitPaymentsAction({
          ...this.paymentSubmit,
          document: {
            date:this.form.generated_to,
            note:this.form.note,
          },
          ...this.form,
          payments: this.data.map(el => ({id: el.id, salary: el.salary}))
        })
      }
    }
  },
  mounted() {
    this.fetchEmployees()
    this.fetchContracts()
    this.fetchShifts()
    this.fetchCashs()
  },
  props: {
    readMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      data: 'payments/paymentData',
      headers: 'payments/paymentHeaders',
      isThereCalculation: 'payments/isThereCalculation',
      employees: 'employees/employees',
      contracts: 'employees/contracts',
      shifts: 'employees/shifts',
      cashs: 'employees/cashs',
    }),
    summary() {
      return this.data.reduce((p, c) => p + c.salary ?? 0, 0);
    }
  },
  watch: {
    item(nV) {
      this.form = {...this.form, ...nV}
    }
  },
  created (){
    if(this.readMode){
      this.restorePayment(this.form)
    }
    
  },


}
</script>

<style scoped>

</style>
